import api from '../api';

class InsuranceService {
    async findAllInsurance() {
        return new Promise((res, reject) => {
            return api.get('/insurance').then(data => {
                res(data);
            }).catch(err => {
                reject();
            })
        })
    };

    async findAllSubInsurance() {
        return new Promise((res, reject) => {
            return api.get('/insuranceSubTypes').then(data => {
                res(data);
            }).catch(err => {
                reject();
            })
        })
    };
}

const insuranceService = new InsuranceService();

export default insuranceService;

import api from '../api';
import React from "react";

class OrderService extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    async getCurrentUser(){
        return new Promise((res, err) => {
            let creds = window.sessionStorage.getItem('currentUser');
            res(JSON.parse(creds));
        })
    }

    async getOrdersByAccount(accountId, params){
        return new Promise((res, reject) => {

            let url = '/accounts/'+ accountId + '/orders';

            return api.get(url, params).then(data => {
                res(data);
            }).catch(err => {
                reject();
            })
        })
    }

    async getOrdersByAccountPNDOB(accountId, params){
        return new Promise((res, reject) => {
            let url = '/accounts/'+ accountId + '/orders/pndob';

            return api.get(url, params).then(data => {
                res(data);
            }).catch(err => {
                reject();
            })
        })
    }

    async getActivity(id){
        return new Promise((res, reject) => {
            api.get('/activities/' + id).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            })
        })
    };

    async getOrdersHLAll(){
        return new Promise((res, reject) => {
            api
                .get('/homeLinkEntitiesNative')
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }
    async getOrdersHLByName(name){
        return new Promise((res, reject) => {
            api
                .get('/homeLinkEntitiesNative', {patientName: name})
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }
    //could be {data: order}
    async updateOrder(order) {
        return new Promise((res, reject) => {
            return api.put('/AC/activities/' + order.id, order)
                .then(data => {
                    res(data);
                }).catch(err => {
                    reject();
                });
        })
    };

    // async uploadDocument(order, file){
    //     return new Promise((res, reject) => {
    //         return api.downloadPost('/activities/' + order.id + '/files/' + file[0].name)
    //             .then(data => {
    //                 res(data);
    //             }).catch(err => {
    //                 reject();
    //             });
    //     })
    // };
    //
    // async downloadDocument(id){
    //     return new Promise((res, reject) => {
    //         return api.getFile('/files/' + id).then(data => {
    //             res(data);
    //         }).catch(err => {
    //             reject();
    //         })
    //     })
    // };


    async getOrderTimeline(order){
        return new Promise((res, reject)=> {
            return api.get('/orderHistory/' + order.id)
                .then(data => {
                    res(data);
                }).catch(err => {
                    reject();
                })
        })
    };

    async getStatusReasons(){
        return new Promise((res, reject) => {
            return api.get('/reasons')
                .then(data => {
                    res(data);
                }).catch(err => {
                    reject();
                })
        })
    };


}

const orderService = new OrderService();

export default orderService;
import React from "react";
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    CardGroup,
    Card,
    CardBody,
    Col,
    Fa,
    Row,
} from "mdbreact";
import './home.css';
import { Route } from "react-router-dom";
import passwordHash from "password-hash";

export default class cardList extends React.Component {
    constructor(props){
        super(props);

        //set function from parent
        this.toggleTimelineModalPopup = props.toggleTimelineFunction;
        this.toggleNoteTooltip = props.toggleNotesFunction;

        this.state={
            orders: props.orders || [],
            sortedOrders: props.sortedOrders || [],
            timelineModalOpen:false,
            timelineModalOrder: {},
            collapseID: ""
        }


    }

    static contextTypes={
        accountOrders: PropTypes.array,
        ordersLoaded:PropTypes.bool
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.sortedOrders !== this.state.sortedOrders){
            this.setState({sortedOrders: nextProps.sortedOrders});
            return true;
        }
        return false;
    }

    formatStatus(order){
        let obj = {
            text: '',
            color: ''
        };

        if(order.status === 'Ready to Deliver'){
            obj.text = 'Waiting for Delivery';
            obj.color = 'statusButtonDelivery';
        }
        else if(order.status === 'Inprocess'){
            if(order.description && order.description === 'Verification'){
                obj.text = 'Intake / Insurance Verification';
                obj.color = 'statusButtonIntake';
            }
            else{
                obj.text = 'Insurance Review';
                obj.color = 'statusButtonInsurance';
            }
        }
        else if (order.status === 'New'){

            obj.text = 'Medical Documents';
            obj.color = 'statusButtonMedical';

        }
        else{
           obj.text = order.status;
           obj.color = 'statusButtonDelivery';
        }

        return obj;
    }

    titleCase(name){
        if(name) {
            return name.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ');
        }
    }

    renderNameRouteButton(order){

        let hashedPassword = passwordHash.generate(String(order.id));

        return(
            <Route render={({history}) => (
                <div  onClick={() => {history.push({
                    pathname: '/order/' + hashedPassword,
                    state: { order: order }
                })}} >

                    <h5>
                        {order.patientName ? this.titleCase(order.patientName) : "New order, Information Loading"}
                    </h5>
                    <Fa icon={'phone'}> </Fa> &nbsp;{order.phone} <br/>
                    <h8> {order.city}, {order.state} </h8>

                </div>
            )}/>
        )
    }

    renderOrderAge(d){
        if(d == null){
            return 0;
        }

        let a = new Date().getTime(),
            oneDay = 1000*60*60*24,
            c = a - Date.parse(d),
            v = Math.round(c/oneDay);

        return(v)

    }

    renderCardGroup() {
        return (
            <CardGroup className={'clCardGroup'} deck>
                {this.state.sortedOrders.map((item, idx) => {
                    return this.renderCard4(item, idx);
                })}
            </CardGroup>
        );
    }

    renderCard4(item, idx){
        let status = this.formatStatus(item),
            ageColor = 'ageButtonBackgroundColor';

        return(
            <div key={idx} className={'clCardSizing'}>
                <Col md={'12'} style={{paddingLeft: 0, paddingRight:0}}>
                    <ButtonGroup className={'clCardButtonGroup'}>
                        <Button  onClick={() => this.toggleTimelineModalPopup(item)}
                            className={ageColor + ' clCardAgeButton'}>
                            {this.renderOrderAge(item.startedAt)}
                            <br/>
                            <span style={{fontSize:'12px'}}>
                                days old
                            </span>
                        </Button>
                        <Button onClick={() => this.toggleTimelineModalPopup(item)}
                            className={status.color + ' clStatusButton'} >
                             <span style={{fontSize:'12px'}}>
                                {this.renderOrderAge(item.lastStatusChange)} days in
                            </span>
                            <br/>
                            {status.text}

                        </Button>
                    </ButtonGroup>
                </Col>
                <Card className={"nameRouteCard"}>
                    <CardBody>
                        <Row>
                            <Col style={{cursor:'pointer'}}>
                                {this.renderNameRouteButton(item)}
                            </Col>
                        </Row>
                    </CardBody>

                </Card>



            </div>


        )
    }

    render(){
        const {currentTheme} = this.context;
        return(
            <div>
                {this.renderCardGroup(currentTheme)}
            </div>
        )

    }

}
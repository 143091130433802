import React from "react";
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import AuthService from '../Security/authService';
import {
    Button,
    Card,
    CardBody, CardHeader,
    Input,
    Row,
    Modal,
    ModalBody,
    toast, Container, Spinner,

} from "mdbreact";

import './login.css';
import Logo from "../Images/RehabSeattingLogo.png";
import userService from "../Security/UserService/userService";

export default class login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loggedIn: false,
            error: '',
            currentUser: {},
            resetModalOpen: false,
            newPw: "",
            retypePw: "",
            pin: "",
            timer: false,
        }
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        userFunction: () => { },
    };

    checkPasswordExpiration(user) {
        const { userFunction } = this.context,
            time = new Date().getTime();

        //the current password needs reset and the user can't login
        if (user.passwordResetDate < time && time < (user.passwordResetDate + 172800000) && !["ADMIN", "SYSTEM_ADMIN"].includes(user.role)) {
            //if right now is greater than reset date but less than reset date + 2 days...BC
            this.setState({ resetModalOpen: true });
        }
        else if (new Date().getTime() > user.passwordResetDate + 172800000 && !["ADMIN", "SYSTEM_ADMIN"].includes(user.role)) {
            //if right now is greater than password reset date + 2...BC
            this.setState({
                error: 'Your account has expired.  Please contact your sales rep.'
            });
            return;

        }
        else {
            //now should be any time greater than 2 days for the password reset date...BC
            this.setState({ loggedIn: true });
            userFunction(user);
        }

    }

    checkRole(user) {
        let permissions = false;

        if (user && user.role) {
            switch (user.role) {
                case 'THIRD_PARTY_USER':
                case 'ADMIN':
                case 'SYSTEM_ADMIN':
                    permissions = true;
                    break;
                default:
                    permissions = false;
                    break;
            }
        }
        if (!permissions) {
            this.setState({ error: "You do not have permissions to view this app" });
            return;
        }
        this.checkPasswordExpiration(user)
    }

    //Attempt to log the user into the service
    validateAndLogin(evt) {
        console.log(evt)
        evt.preventDefault();
        AuthService.login(this.state)
            .then((res) => {
            //successfully logged in...BC
                console.log(res)
                if (res) {
                    AuthService.getMyUserDetails().then(user => {
                        this.setState({ currentUser: user });
                        window.sessionStorage.setItem('currentUser', JSON.stringify(user));
                        this.checkRole(user);
                        this.startLogInTimer();
                    })
                }
                else {
                    console.log("There was an error logging in.");
                }
        }).catch(err => {
            //failed to login...BC
            this.setState({ error: err });
        });



    }
    toggleResetModalPopup() {
        let currentState = this.state.resetModalOpen;
        this.setState({ resetModalOpen: !currentState });
    }

    updatePassword() {
        if (this.state.newPw === '') {
            toast.warn('You must type a new password');
            return;
        }

        if (this.state.newPw !== this.state.retypePw) {
            toast.warn('Passwords do not match');
            return;
        }

        if (this.state.pin === "") {
            toast.warn('You must enter a pin');
            return;
        }



        let currentUser = this.state.currentUser,
            d = new Date();

        currentUser.password = this.state.newPw;
        currentUser.retypePassword = this.state.retypePw;
        currentUser.pin = this.state.pin;
        currentUser.passwordResetDate = d.setDate(d.getDate() + 90);

        userService.updateUserPassword(currentUser).then(res => {
            this.setState({
                resetModalOpen: false
            });
            toast.success("Password has been changed.  Please login with the new password.");
        }).catch(e => {
            toast.error("There was an error updating your password.  Check your unique pin.");
        })
    }

    textChange = (property, event) => {
        this.setState({ [property]: event.target.value });
    };

    renderResetModal() {
        return (
            <Modal
                centered
                className="form-elegant passwordModal"
                isOpen={this.state.resetModalOpen}
                toggle={() => this.toggleResetModalPopup()}>
                <ModalBody className="pvNoteModal" >
                    <CardHeader color={'red darken-1'} className="form-header text-center">
                        Reset Your Password
                    </CardHeader>
                    <Input label="New Password"
                        value={this.state.newPw}
                        icon="lock"
                        group
                        type="password"
                        validate
                        onChange={this.textChange.bind(this, 'newPw')}
                    />
                    <Input label="Repeat Password"
                        icon="undo"
                        group
                        type="password"
                        validate
                        value={this.state.retypePw}
                        onChange={this.textChange.bind(this, 'retypePw')}
                    />

                    <Input label="Unique Pin"
                        icon="key"
                        group
                        type="password"
                        validate
                        value={this.state.pin}
                        onChange={this.textChange.bind(this, 'pin')}
                    />

                    <Row style={{ justifyContent: 'space-around' }}>
                        <Button color={'blue darken-1'} onClick={this.updatePassword.bind(this)}>Update Password</Button>
                    </Row>

                </ModalBody>
            </Modal>
        )
    }

    startLogInTimer() {
        setTimeout(() => this.setState({ timer: true }), 500)
    }
    redirectToHome() {
        if (this.state.timer) {
            return (<Redirect to='/orders' />);
        }
        return (
            <Container className="mt-5">
                <div className={'dbLoadingSpinner'}>
                    <Spinner multicolor />
                </div>
            </Container>
        )
    }

    render() {
        if (this.state.loggedIn === true) {
            return this.redirectToHome();
        }

        return (

            <div className={'loginDiv'}>
                {this.renderResetModal()}
                <Card className={'w-25 loginCard'}>
                    <form className={'loginDiv'} onSubmit={this.validateAndLogin.bind(this)}>
                        <img src={Logo} width='250' className={"img-fluid loginPageImage"} alt={"Rehab Seating"} />
                        <CardBody>
                            <div>
                                <Input onChange={(evt) => this.setState({ username: evt.target.value })} type='text' label="Username" icon="user-circle" />
                                <br />
                                <Input onChange={(evt) => this.setState({ password: evt.target.value })} label="Password" icon="lock" group type="password" validate />
                            </div>
                            <div className="text-center">
                                <h1 className={'loginPageErrorText'}>{this.state.error}</h1>
                                <Button type='submit' color='red darken-2'>
                                    <h3>Login</h3>
                                </Button>

                            </div>
                        </CardBody>
                    </form>
                </Card>
            </div>

        )
    }
}



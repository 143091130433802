import React, {Component} from "react";
import PropTypes from 'prop-types';
import {BrowserRouter as Router} from "react-router-dom";
import {
    Navbar,
    NavbarBrand,
    NavbarNav,
    NavbarToggler,
    Collapse,
    NavItem,
    Fa,
    Footer,
    NavLink,
    // Row,
    // Col,
    ToastContainer,
} from "mdbreact";

import "./index.css";
import homeLinkLogo from './Seating/Images/homelinkLogo.png';
import Logo from './Seating/Images/RehabSeattingLogo-HZ6.png';
import Routes from "./Routes";
import AuthService from "./Seating/Security/authService";
import InsuranceService from "./Seating/Security/InsuranceService/insuranceService"
// import UserService from "./Seating/Security/UserService/userService";
import OrderService from "./Seating/Security/OrderService/orderService";


const AppContext = React.createContext();

class App extends Component {
    constructor(props) {
        super(props);


        let creds = window.sessionStorage.getItem('currentUser'),
            acc = {};
        if(creds){
            creds = JSON.parse(creds);
            acc = creds.account || {};
        }

        this.state = {
            collapseID: "",
            currentUser: creds || {},
            currentAccount: acc,
            insuranceTypes: [],
            insuranceSubTypes: [],
            allTherapists: [],
            allReasons: [],
            userContextLoaded: false,
            accountOrders: [],
            ordersLoaded: false,
        };
    }

    static childContextTypes = {
        userFunction: () => {},
        accountFunction: () => {},
        currentUser: PropTypes.object,
        currentAccount: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        statusReasons: PropTypes.shape,
        ordersLoaded: PropTypes.bool,
    };

    getChildContext() {
        return {
            currentAccount: this.state.currentAccount,
            userFunction: this.setUserFunction.bind(this),
            accountFunction: this.setAccountFunction.bind(this),
            currentUser: this.state.currentUser,
            insuranceTypes: this.state.insuranceTypes,
            insuranceSubTypes: this.state.insuranceSubTypes,
            statusReasons: this.state.allReasons,
            ordersLoaded: this.state.ordersLoaded
        }
    };

    componentDidMount() {
        //only want to try to get the context stuff if the user is logged in.
        if (this.state.currentUser.id) {
            this.getAll();
        }
    }

    getAll() {
        this.getWhoAmI();
        this.getInsuranceTypes();
        this.getStatusReasons();
    }

    setUserFunction(user) {
        let account = user.account || {};

        this.setState({
            currentAccount: account,
            currentUser: user,
            userContextLoaded: true,
        });

        this.getAll();
    };

    setAccountFunction(account){
        //if the user is an admin, allow them to change the account they want to see orders for

        this.setState({
            currentAccount: account
        });

    }

    getWhoAmI() {
        let ucl = true;

        AuthService.getMyUserDetails().then(user => {
            //need to do this check because if the user logs in with expired credentials we don't want them to see the navbar...BC
            if(user.passwordResetDate + 172800000 < new Date().getTime()){
                ucl = false;
            }

            this.setState({
                currentUser: user,
                currentAccount: user.account || {},
                userContextLoaded: ucl
            });
        }).catch(err => {
            //do nothing...BC
        })
    }

    getInsuranceTypes() {
        InsuranceService.findAllInsurance().then(types => {
            this.setState({insuranceTypes: types});
        }).catch(err => {
            //do nothing ...BC
        });

        InsuranceService.findAllSubInsurance().then(types => {
            this.setState({insuranceSubTypes: types});
        }).catch(err => {
            //do nothing ...BC
        })
    }

    getStatusReasons() {
        return OrderService.getStatusReasons().then(reasons => {
            let dict = new Map();
            reasons.forEach((r) => {
                dict.set(r.description, r.thirdPartyDescription);
            });

            this.setState({allReasons: dict});
        }).catch(err => {

        })
    }

    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));

    closeCollapse = collapseID => () =>
        this.state.collapseID === collapseID && this.setState({collapseID: ""});


    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    renderNavBarItems() {
        if(window.location.pathname === "/" || window.location.pathname === "/login"){
            return (<div style={{display:'none'}}/>)
        }
        if (!this.state.userContextLoaded) {
            //don't need a navbar here.  show nothing...BC
            return (<div style={{display:'none'}}/>)
        } else {
            return (
                <Navbar style={{backgroundColor: "#000000"}} dark expand="lg" fixed="top" scrolling>

                    <NavbarBrand href="/orders" style={{marginRight: "40px"}}>
                        <img src={Logo} width='225' className={"img-fluid"} alt={"Rehab Seating"}/>
                    </NavbarBrand>
                    <NavbarBrand href="/orders">
                        <img src={homeLinkLogo} width='225' className={"img-fluid"} alt={"Home Link"}/>
                    </NavbarBrand>
                    <NavbarToggler
                        onClick={this.toggleCollapse("mainNavbarCollapse")}
                    />
                    <Collapse
                        id="mainNavbarCollapse"
                        isOpen={this.state.collapseID}
                        navbar
                    >
                        <NavbarNav right>
                            <NavItem>
                                <NavLink
                                    onClick={this.closeCollapse("mainNavbarCollapse")}
                                    to="/orders">
                                    <h4>
                                        Open Orders
                                    </h4>
                                </NavLink>
                            </NavItem>

                            <div style={{paddingRight: '50px'}}/>

                            <NavItem>
                                <NavLink
                                    onClick={this.closeCollapse("mainNavbarCollapse")}
                                    to="/patientList">
                                    <h4>
                                        Patient Archive
                                    </h4>
                                </NavLink>
                            </NavItem>

                            <div style={{paddingRight: '50px'}}/>

                            <NavItem>
                                <NavLink
                                    onClick={() => {
                                        this.closeCollapse("mainNavbarCollapse");
                                        window.sessionStorage.setItem('currentUser', "");
                                        this.setState({loggedIn: false, userContextLoaded: false});
                                    }}
                                    to={"/login"}
                                >
                                    <h4>
                                        <Fa icon='sign-out'/>
                                    </h4>
                                </NavLink>
                            </NavItem>
                        </NavbarNav>
                    </Collapse>
                </Navbar>
            )
        }
    }

    render() {
        //const overlay = (
        //    <div
        //        id="sidenav-overlay"
        //        style={{backgroundColor: "transparent"}}
        //        onClick={this.toggleCollapse("mainNavbarCollapse")}
        //    />
        //);

        return (
            <AppContext.Provider value={this.state}>
                <Router>
                    <div className={"flyout grey lighten-2"}>

                        <div>
                            <ToastContainer
                                hideProgressBar={false}
                                newestOnTop={true}
                                autoClose={5000}
                            />
                            <div style={{marginBottom: '93px'}}>
                                {this.renderNavBarItems()}
                            </div>

                            {/*{this.state.collapseID && overlay}*/}
                            <main>
                                <Routes userContextLoaded={this.state.userContextLoaded}/>
                            </main>
                        </div>

                        <Footer   color={'black'}>
                            <p className="footer-copyright mb-0 py-3 text-center">
                                &copy; {new Date().getFullYear()} Rehab Medical
                            </p>
                        </Footer>

                    </div>

                </Router>
            </AppContext.Provider>
        );
    }
}

export default App;
export const AppContextConsumer = AppContext.Consumer;
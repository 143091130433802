import api from '../api';

class NoteService {

    async getOpenTherapistNotesByOwner() {
        return new Promise((res, reject) => {
            let params={
                page: 0,
                size: 100,
                openTasks: true
            };

            return api.get('/therapistNotes/tasks', params).then(data => {
                res(data);
            }).catch(err => {

            })
        });
    };

    async getTherapistNotes(id){
        return new Promise((res, reject) => {

            let url = '/therapistNotes/'+ id;

            return api.get(url).then(data => {
                res(data);
            }).catch(err => {
                reject();
            })
        })
    }

    async createTherapistNote(note){
        return new Promise((res, reject) => {

            return api.post('/therapistNotes',  note).then(createdNote => {
                res(createdNote.json());
            })
                .catch(err => {
                    reject(err);
                })
        })
    }

    async updateTherapistNote(note){
        let id = note.id;

        return new Promise((res, reject) => {
            return api.put('/therapistNotes/'+ id, note).then(note => {
                res(note);
            })
            .catch(err => {
                reject(err);
            })
        })
    }

}

const noteService = new NoteService();

export default noteService;

import base64encode from './base64encode';

class Api {

    // BUILD base url based on company

    api = function(config, endpointURL){

        return new Promise((res, reject) => {
            fetch(endpointURL, config).then((data) => {
                if(data.status >= 200 && data.status < 300){
                    return data;
                }
                else{
                    return 'error: status code: ' + data.status;
                }
            }).catch(err => {
                return err;
            })
            .then(data => {
                if(data && typeof(data) === "string" && data.indexOf('error: status code:') > -1){
                    reject('There was an error with the post data; ' + data);
                }
                else{
                    res(data);
                }
            })

        })
    };


    buildURL(company) {
        //const URL_DATA = {
        //    //DEV_DOMAIN: 'http://10.0.17.146:8080',
        //    DEV_DOMAIN: 'http://localhost:8080',
        //    PROTOCOL: 'https',
        //    TOP_DOMAIN: '.salespilot.com',
        //    API_PATH: '/api'
        //};

        //var baseUrl = "https://localhost:44318/api";
        var baseUrl = "https://homelink-api.rehabmedical-tech.com/api";

        //if(company === 'dev' || company === 'local') {
        //    baseUrl = //URL_DATA.DEV_DOMAIN + URL_DATA.API_PATH;
        //} else {
        //    baseUrl = URL_DATA.PROTOCOL + '://' + company + URL_DATA.TOP_DOMAIN  + URL_DATA.API_PATH;
        //}

        return baseUrl;
    }

    // BUILD HTTP CONFIG (headers, method, body)
    // TYPE is (POST, PUT, DELETE, GET)
    // TOKEN is session token
    // CONTENT is only used for POST && PUT

    buildConfig(type, token, content) {
        var credentials = token.username + ':' + token.token;
        var config = {};

        config['method'] = type;

        config['headers'] = {};
        config.headers['Authorization'] = 'Basic ' + base64encode.b2a(credentials);
        if(type ==='put' || type === 'post'){
            config.headers['Accept'] = 'application/json, text/plain, */*';
            config.headers['Content-Type'] = 'application/json';
            if(content != null && content !== undefined){
                config.body = JSON.stringify(content);
            }
        }
        return config;
    }



    buildDownloadConfig(type, token){
        var credentials = token.username + ':' + token.token;
        var config = {};

        config['method'] = type;

        config['headers'] = {};
        config.headers['Authorization'] = 'Basic ' + base64encode.b2a(credentials);

        config.headers['Accept'] = 'application/json, text/plain, */*';
        //config.headers['Content-Type'] = 'application/json';
        //config.headers['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundaryYmgdVsHhDRIeTHP9';

        var formData = new FormData();
        var fileField = document.querySelector("input[type='file']");
        formData.append('file', fileField.files[0]);
        config.body = formData;

        return config;
    }
    // GET map query parameters to string for concat onto url

    buildQueryParams(params){
        var esc = encodeURIComponent;
        return '?' + Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }

    // GET SESSION TOKEN from memory must be included in server calls

    async getToken(){
        return new Promise((res, err) => {
            let creds = window.sessionStorage.getItem('tokenCredentials');
            res(JSON.parse(creds));
        })

    }

    // ENDPOINT parameter should be a STRING that includes the portion of the URL after ***.salespilot.com/api
    // PARAMS should be JSON of prarmeters fitting the required endpoint parameters.

    async get(endpoint, params){
        return new Promise((res, reject) => {
            this.getToken().then(token => {

                if(token == null){
                    reject("token does not exist");
                    return;
                }

                let endpointURL = this.buildURL(token.company) + endpoint;

                if(params){
                    endpointURL = endpointURL + this.buildQueryParams(params);
                }

                let config = this.buildConfig('get', token);

                fetch(endpointURL, config).then((data) => {
                    // console.log(data, "data");
                    if(data.status >= 200 && data.status < 300){
                        return data;
                    }
                    else if(data.status === 403){
                        reject('403 Forbidden');
                    }
                    else{
                        reject();
                    }
                })
                    .catch(err => {
                        return 'error'
                    })

                    .then(data => {
                        if(data){
                            if(data === 'error'){
                                reject();
                            }
                            else{
                                res(data.json());
                            }
                        }
                        else{
                            res();
                        }
                    })
            })
        })
    }

    // ENDPOINT parameter should be a STRING that includes the portion of the URL after ***.salespilot.com/api
    // CONTENT parameter should be JSON or ARRAY of the information you wish to post.

    async post(endpoint, content){
        return new Promise((res, reject) => {
            this.getToken().then(token => {
                console.log(token)
                // console.log('content', content);
                let endpointURL = this.buildURL(token.company) + endpoint,
                    config = this.buildConfig('post', token, content);

                fetch(endpointURL, config).then((data) => {
                    if(data.status >= 200 && data.status < 300){
                        return data;
                    }
                    else{
                        return 'error: status code: ' + data.status;
                    }
                }).catch(err => {
                    return err;
                })

                    .then(data => {
                        if(data && typeof(data) === "string" && data.indexOf('error: status code:') > -1){
                            reject('There was an error with the post data; ' + data);
                        }
                        else{
                            res(data);
                        }
                    })
            })
        })
    }

    // ENDPOINT parameter should be a STRING that includes the portion of the URL after ***.salespilot.com/api
    // CONTENT parameter should be JSON or ARRAY of the information you wish to post.

    async put(endpoint, content){
        return new Promise((res, reject) => {
            this.getToken().then(token => {
                let endpointURL = this.buildURL(token.company) + endpoint,
                    config = this.buildConfig('put', token, content);

                fetch(endpointURL, config).then((data) => {

                    if(data.status >= 200 && data.status < 300){
                        return data;
                    } else{
                        throw new Error(data.status + ":" + data.statusText);
                    }

                }).catch(err => {
                    return 'error';
                })

                    .then(data => {
                        if(data){
                            if(data === 'error'){
                                reject();
                            }
                            else{
                                res(data.json());
                            }
                        }
                        else{
                            res();
                        }
                    })
            })
        })
    }

    async downloadPost(endpoint){
        return new Promise((res, reject) => {
            this.getToken().then(token => {
                // console.log('content', content);
                let endpointURL = this.buildURL(token.company) + endpoint,
                    config = this.buildDownloadConfig('post', token);

                fetch(endpointURL, config).then((data) => {
                    if(data.status >= 200 && data.status < 300){
                        return data;
                    }
                    else{
                        return 'error: status code: ' + data.status;
                    }
                }).catch(err => {
                    return err;
                })

                    .then(data => {
                        if(data && typeof(data) === "string" && data.indexOf('error: status code:') > -1){
                            reject('There was an error with the post data; ' + data);
                        }
                        else{
                            res(data);
                        }
                    })
            })
        })
    }

    async getFile(endpoint, params) {
        return new Promise((res, reject) => {
            this.getToken().then(token => {

                if(token == null){
                    reject("token does not exist");
                    return;
                }

                let endpointURL = this.buildURL(token.company) + endpoint;

                if(params){
                    endpointURL = endpointURL + this.buildQueryParams(params);
                }

                let config = this.buildConfig('get', token);

                fetch(endpointURL, config).then((data) => {
                    // console.log(data, "data");
                    if(data.status >= 200 && data.status < 300){
                        return data;
                    }
                    else{
                        reject();
                    }
                })
                    .catch(err => {
                        return 'error'
                    })
                    .then(data => {
                        if(data){
                            if(data === 'error'){
                                reject();
                            }
                            else{
                                res(data.blob());
                            }
                        }
                        else{
                            res();
                        }
                    })
            })
        })
    }


}
const api = new Api();

export default api;
import api from '../api';

class UserService {

    async getCurrentUser(){
        return new Promise((res, err) => {
            let creds = window.sessionStorage.getItem('currentUser');
            res(JSON.parse(creds));
        })

    }

    //used in the find function to get the first seating clinic the user belongs to...BC
    getSeatingClinic(location){
        if(location.type === "SeatingClinic"){
            return true;
        }
    }

    async getTherapists(){
        let user = {};

        await this.getCurrentUser().then(u => {
            user = u;
        });

        let clinic = user.locations.find(this.getSeatingClinic);

        if(clinic == null){
            return [];
        }

        return new Promise((res, reject) => {
            let params ={
                page:0,
                size: 100,
                paged: false
            };
            api.get('/findUsersByLocation/' + clinic.id, params).then(data => {
                res(data);
            }).catch(err => {
                reject();
            });
        })
    }

    async updateUserPassword(user) {
        return new Promise((res, reject) => {
            api.put('/AC/users/' + user.id, user).then(data =>{
                res(data);
            }).catch(err => {
                reject();
            })
        })
    }

    async updateUser(user) {
        return new Promise((res, reject) => {
            api.put('/SC/users/' + user.id, user).then(data =>{
                res(data);
            }).catch(err => {
                reject();
            })
        })
    }

    async getUser(id){
        return new Promise((res, reject) => {
            api.get('/SC/users/' + id).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            })
        })
    }


    async createUser(user){
        let currentUser = {};

        await this.getCurrentUser().then(u => {
            currentUser = u;
        });

        let clinic = currentUser.locations.find(this.getSeatingClinic);

        if(clinic == null){
            return [];
        }

        user.locations = [];
        user.locations.push(clinic);

        return new Promise((res, reject) => {
            api.post('/SC/users', user).then(data => {

                res(data.json());
            }).catch(err => {
                reject();
            })
        })
    }


}

const userService = new UserService();

export default userService;